import eventBus from '@carwow/carwow_theme/app/javascript/event_bus'

export default function initOpenGalleryOnIndex () {
  eventBus.on('slider-slide-change', (e) => {
    const slider = document.getElementById(e.galleryId)

    if (slider) {
      slider.parentElement.setAttribute('data-gallery-starting-index', e.currentSlideIndex)
    }
  })
}
