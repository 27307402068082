// polyfills
import 'intersection-observer'

import Rails from '@rails/ujs'
import '@carwow/carwow_theme/app/javascript/theme_common'
import 'shared/referrer_tracking'

// JS-based image lazy loading is the legacy solution. We have moved all our code to
// use native lazy loading intead. However, I/O agency pages still use these data atrributes
// We need to load this to make sure the images are loaded on these pages.
import 'shared/lazy_srcset'

Rails.start()
