import 'shared/common'

import '@carwow/carwow_theme/app/javascript/components/video'
import '@carwow/carwow_theme/app/javascript/components/media_slider'
import { initGallery } from '@carwow/carwow_theme/app/javascript/components/gallery'
import initOpenGalleryOnIndex from 'shared/gallery'

document.addEventListener('DOMContentLoaded', () => {
  initGallery(document)
  initOpenGalleryOnIndex()
})
