import { setItem } from '@carwow/carwow_theme/app/javascript/doc_cookies'

function getParameterByName (name) {
  const searchName = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
  const regex = new RegExp('[\\?&]' + searchName + '=([^&#]*)')
  const results = regex.exec(window.location.search)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

const domain = document.domain.replace(/^[a-zA-Z]+?\./, '')
const utmMedium = getParameterByName('utm_medium')
const utmSource = getParameterByName('utm_source')
const utmCampaign = getParameterByName('utm_campaign')
const utmKeyword = getParameterByName('utm_keyword')
const utmGroup = getParameterByName('utm_group')
const gclid = getParameterByName('gclid')
const type = getParameterByName('Type')
const makeModel = getParameterByName('MakeModel').split('|')
const make = makeModel[0]
const model = makeModel[1]
const network = getParameterByName('network')

const threeHours = 3 * 60 * 60 * 1000

if (utmMedium !== '') {
  setItem('user_utm_medium', utmMedium, threeHours, '/', domain)
}

if (utmSource !== '') {
  setItem('user_utm_source', utmSource, threeHours, '/', domain)
}

if (utmCampaign !== '') {
  setItem('user_utm_campaign', utmCampaign, threeHours, '/', domain)
}

if (utmKeyword !== '') {
  setItem('user_utm_keyword', utmKeyword, threeHours, '/', domain)
}

if (utmGroup !== '') {
  setItem('user_utm_group', utmGroup, threeHours, '/', domain)
}

if (gclid !== '') {
  setItem('user_gclid', gclid, threeHours, '/', domain)
}

if (type !== '') {
  setItem('car_type', type, threeHours, '/', domain)
}

if (network !== '') {
  setItem('user_search_partner', network, threeHours, '/', domain)
}

if (make !== undefined && make !== '') {
  setItem('car_make', make, threeHours, '/', domain)
}

if (model !== undefined && model !== '') {
  setItem('car_model', model, threeHours, '/', domain)
}
