const LAZY_SRCSET_ATTRIBUTE_NAME = 'data-lazy-srcset'

function getElementWidth (element) {
  return element.getBoundingClientRect().width
}

function suitableSrc (element, srcset) {
  const srcsWithSize = srcset.split(',')
  const width = getElementWidth(element)
  let srcAndWidth = []

  for (let i = 0; i < srcsWithSize.length; i += 1) {
    srcAndWidth = srcsWithSize[i].split(' ')

    if (parseFloat(srcAndWidth[1]) >= width) {
      break
    }
  }

  return srcAndWidth[0]
}

function loadImage (element) {
  const srcSet = element.getAttribute(LAZY_SRCSET_ATTRIBUTE_NAME)
  if (!srcSet) { return }

  let img = element
  if (element.tagName !== 'IMG') {
    img = document.createElement('img')
    img.sizes = getElementWidth(element) + 'px'
    img.addEventListener('load', () => {
      const imgSrc = img.currentSrc || suitableSrc(element, img.srcset) || img.src
      element.style.backgroundImage = `url(${imgSrc})`
    })
  }

  const fallbackUrl = srcSet.split(' ')[0]
  img.srcset = srcSet
  img.src = fallbackUrl
}

function handleMutation (observe, mutations) {
  mutations.forEach(mutation => {
    if (mutation.type === 'attributes') {
      loadImage(mutation.target)
    }
    if (mutation.type === 'childList') {
      observe(mutation.target)
    }
  })
}

function makeIntersectionObserver () {
  const opts = { rootMargin: '20%' }
  const observer = new window.IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        loadImage(entry.target)
        observer.unobserve(entry.target)
      }
    })
  }, opts)
  observer.POLL_INTERVAL = 100

  return observer
}

function observeChildren ({ intersection, mutation }, rootElement) {
  const nodes = rootElement.querySelectorAll(`[${LAZY_SRCSET_ATTRIBUTE_NAME}]`)
  nodes.forEach((e) => mutation.observe(e, {
    attributeFilter: [LAZY_SRCSET_ATTRIBUTE_NAME],
    attributes: true
  }))

  nodes.forEach(intersection.observe.bind(intersection))

  const remoteContentNodes = rootElement.querySelectorAll('carwow-remote-content')
  remoteContentNodes.forEach((e) => mutation.observe(e, { childList: true }))
}

function bootstrap () {
  const ctx = {}
  const observe = observeChildren.bind(null, ctx)

  ctx.intersection = makeIntersectionObserver()
  ctx.mutation = new window.MutationObserver(handleMutation.bind(null, observe))

  observe(document.body)
}

document.addEventListener('DOMContentLoaded', bootstrap)
